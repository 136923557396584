import React, { useContext, useState } from "react";
import {
  BoldLink,
  BoxContainer,
  CenteredContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from "./accountContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function LoginForm(props) {
  const { switchToSignup } = useContext(AccountContext);
  const { switchToVerify } = useContext(AccountContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    // Create an object with email and password
    const userData = { email, password };

    try {
      // Send a POST request to the login endpoint using fetch
      const response = await fetch("https://hstonline.tech/hstsms/api/v1/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const data = await response.json();
        console.log("Login successful", data);
        localStorage.setItem("token",JSON.stringify(data))

        // Call switchToSignup after a successful login
        toast.success("Login successful");
        switchToVerify();
        

        // You might want to set user authentication state here

      } else {
        // Handle login failure
        console.error("Login failed", response.statusText);
        toast.error("Invalid Password or Email");
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error during login", error);
    }
  };

  return (
    
    
    <BoxContainer>
        
      <FormContainer onSubmit={handleLogin}>
      <ToastContainer />
        <Input
          type="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Marginer direction="vertical" margin={10} />
        {/* <MutedLink href="#">Forget your password?</MutedLink> */}
        <Marginer direction="vertical" margin="1.6em" />
        <CenteredContainer>
        <SubmitButton type="submit">Sign in</SubmitButton>
        </CenteredContainer>
      </FormContainer>
      <Marginer direction="vertical" margin="5px" />
      {/* <LineText>
        Don't have an account?{" "}
        <BoldLink onClick={switchToSignup} href="#">
          Signup
        </BoldLink>
      </LineText> */}
   
    
    </BoxContainer>
  );
}
