import React, { useContext, useState } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from './accountContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function VerifyForm(props) {
  const [ticket_id, setTicketId] = useState("");

  const { switchToSignin } = useContext(AccountContext);

  const handleVerify = async (e) => {
    e.preventDefault();


    // Create an object with email and password
    const userData = { ticket_id };

    const token = JSON.parse(localStorage.getItem("token")) || null;
    let headers = {
      "Content-Type": "application/json",
    };
    
    if (token && token.access_token) {
      headers.Authorization = `Bearer ${token.access_token}`;
    }



    try {
      // Send a POST request to the login endpoint using fetch
      const response = await fetch("https://hstonline.tech/hstsms/api/v1/ticket/verify", {
        method: "POST",

        headers: headers,
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        const data = await response.json();
        const state = data.ticket.state;
        const ticketType = JSON.parse(data.ticket.extra_data).ticket_type
    


        console.log("Ticket is", state);
        if (state === "used"){
          
        }

        switch(state){
          case "used":
            toast.warn(`Ticket is ${state}, Type: ${ticketType}`);
            break;
          case "live":
            toast.success(`Ticket is ${state}, Type ${ticketType}`);
            break;
          default:
            toast.error(`Invalid Ticket`);
        }

        // Call switchToVerify after a successful login
        


        // You might want to set user authentication state here
      } else if (response.status === 404) {
        // Handle 404 (Not Found) response
        console.error("invalid ticket");
        toast.error("Invalid ticket");
      } else {
        // Handle other response statuses
        console.error("Unexpected error");
        toast.error("Unexpected error");

        switchToSignin()
      }

    } catch (error) {
      // Handle network or other errors
      console.error("Error during login", error);
    }
  };
  return (
    <BoxContainer>
      <FormContainer onSubmit={handleVerify}>
        <ToastContainer />
        <Input
          type="text"
          placeholder="Enter Ticket ID"
          required
          value={ticket_id}
          onChange={(e) => setTicketId(e.target.value)}
        />

        <Marginer direction="vertical" margin={10} />
        <SubmitButton type="submit">Verify</SubmitButton>
      </FormContainer>

      <Marginer direction="vertical" margin="5px" />
      <LineText>
        Already have an account?{" "}
        <BoldLink onClick={switchToSignin} href="#">
          Signin
        </BoldLink>

      </LineText>

    </BoxContainer>
  );
}